<template>
    <div class="row">
        <div class="col-lg-6 form-group">
            <b-form-select id="province" v-model="provinceValue" :options="provinceOptions" @change="changeProvince();">
            </b-form-select>
        </div>
        <div class="col-lg-6 form-group">
            <b-form-select id="district" v-model="districtValue" :options="districtOptions" @change="changeDistrict();">
            </b-form-select>
        </div>
        <!-- <div class="col-lg-6 form-group">
            <b-form-select id="ward" v-model="wardValue" :options="wardOptions" @change="changeWard();">
            </b-form-select>
        </div> -->
        <div class="col-lg-12 form-group">
            <b-form-select id="stadium" v-model="stadiumValue" :options="stadiumOptions"
                @change="callBack(provinceValue, districtValue, stadiumValue);">
            </b-form-select>
        </div>
    </div>
</template>


<script>
// import ApiAddress from "../../services/apiAddress";
// import ApiStadium from "../../services/apiStadium";
import { getProvinces } from "../../common/storage";

export default {
    props: {
        province: String,
        district: String,
        // ward: String,
        stadium: String,
        callBack: {
            type: Function
        }
    },
    data() {
        return {
            provinceValue: "",
            districtValue: "",
            // wardValue: "",
            stadiumValue: "",
            provinceOptions: [],
            provinceItem: { value: "", text: 'Tỉnh/Thành phố' },
            districtOptions: [],
            districtItem: { value: "", text: 'Quận/Huyện' },
            // wardOptions: [],
            // wardItem: { value: "", text: 'Phường/Xã' },
            stadiumOptions: [],
            stadiumItem: { value: "", text: 'Sân vận động' },
            loadingItem: { value: "", text: 'Đang tải...' }
        };
    },
    methods: {
        setStadium(item) {
            this.provinceValue = "";
            this.districtValue = "";
            // this.wardValue = "";
            this.stadiumValue = "";
            this.districtOptions = [this.districtItem];
            // this.wardOptions = [this.wardItem];
            this.stadiumOptions = [this.stadiumItem];
            if (item && item.province) this.provinceValue = item.province;
            if (item && item.district) this.districtValue = item.district;
            // if (item && item.ward) this.wardValue = item.ward;
            if (item && item.stadium) this.stadiumValue = item.stadium;
            this.getStadium();
        },
        getItemFilter() {
            return { province: this.provinceValue, district: this.districtValue }; //, ward: this.wardValue
        },
        changeProvince(district = null, stadium = null) { //ward = null, 
            this.districtOptions = [this.districtItem];
            this.districtValue = "";
            // this.wardOptions = [this.wardItem];
            // this.wardValue = "";
            this.stadiumOptions = [this.stadiumItem];
            this.stadiumValue = "";
            if (this.provinceValue) this.getDistrict(this.provinceValue, district, stadium); //ward, 
            if (!district) this.getStadium(stadium);
        },
        changeDistrict(stadium = null) { //ward = null, 
            // this.wardOptions = [this.wardItem];
            // this.wardValue = "";
            this.stadiumOptions = [this.stadiumItem];
            this.stadiumValue = "";
            // if (this.districtValue) this.getWard(this.districtValue, ward, stadium);
            this.getStadium(stadium);
        },
        // changeWard(stadium = null) {
        //     this.stadiumOptions = [this.stadiumItem];
        //     this.stadiumValue = "";
        //     this.getStadium(stadium);
        // },
        async getProvince(province = null, district = null, stadium = null) { // ward = null, 
            this.provinceOptions = [this.loadingItem];
            // this.showLoading(true);
            // const provinces = await ApiAddress.getProvinces();
            const provinces = getProvinces();
            // this.showLoading(false);
            if (provinces && provinces.length > 0) this.provinceOptions = provinces.map(item => {
                return { value: item.province_id, text: `${item.type} ${item.name}` };
            });
            this.provinceOptions.unshift(this.provinceItem);
            if (province) {
                this.provinceValue = province;
                this.changeProvince(district, stadium); //ward, 
            }
        },
        async getDistrict(provinceId, district = null, stadium = null) { //ward = null, 
            this.districtOptions = [this.loadingItem];
            // this.showLoading(true);
            // const districts = await ApiAddress.getDistricts(provinceId);
            const districts = this.getDistrictBys(provinceId);
            // this.showLoading(false);
            if (districts && districts.length > 0) this.districtOptions = districts.map(item => {
                return { value: item.district_id, text: `${item.type} ${item.name}` };
            });
            this.districtOptions.unshift(this.districtItem);
            if (district) {
                this.districtValue = district;
                this.changeDistrict(stadium); //ward, 
            }
        },
        // async getWard(districtId, ward = null, stadium = null) {
        //     this.wardOptions = [this.loadingItem];
        //     // this.showLoading(true);
        //     const wards = await ApiAddress.getWards(districtId);
        //     // this.showLoading(false);
        //     if (wards && wards.length > 0) this.wardOptions = wards.map(item => {
        //         return { value: item.ward_id, text: `${item.type} ${item.name}` };
        //     });
        //     this.wardOptions.unshift(this.wardItem);
        //     if (ward) {
        //         this.wardValue = ward;
        //         this.changeWard(stadium);
        //     }
        // },
        async getStadium(stadium = null) {
            this.stadiumOptions = [this.loadingItem];
            // this.showLoading(true);
            // const data = await ApiStadium.getOption(this.getItemFilter());
            const data = this.getStadiumBys(this.getItemFilter());
            // this.showLoading(false);
            if (data && data.length > 0) this.stadiumOptions = data.map(e => {
                return { value: e.id, text: `${e.name}` }; // (${e.address})
            });
            else this.stadiumOptions = [];
            this.stadiumOptions.unshift(this.stadiumItem);
            if (stadium) this.stadiumValue = stadium;
            this.callBack(this.provinceValue, this.districtValue, this.stadiumValue);
        },
    },
    mounted() {
        this.provinceOptions.unshift(this.provinceItem);
        this.districtOptions.unshift(this.districtItem);
        // this.wardOptions.unshift(this.wardItem);
        this.stadiumOptions.unshift(this.stadiumItem);
        this.getProvince(this.province, this.district, this.stadium); //this.ward, 
        if (!this.province && !this.district) this.getStadium();
    }
};
</script>
